import {ref, computed, watch} from '@vue/composition-api'
import {useToast} from 'vue-toastification/composition'
import ToastificationContent
  from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment-timezone'
import store from '@/store'
import router from '@/router'
import ability from '@/libs/acl/ability'
import {useUtils as useI18nUtils} from "@core/libs/i18n";
import { debounce } from 'lodash'

export default function useList() {
  const {t} = useI18nUtils();
  const trans = {
    "messages.error.server_error": t("messages.error.server_error"),
  };

  const refListTable = ref(null)

  const toast = useToast()

  store.dispatch('type_alarm/getAll')

  const typeAlarmOptions = computed(() => store.getters['type_alarm/getSelectedItems'])
  const typeAlarmFilter = ref(null)

  store.dispatch('tenant/getAll')

  const tenantOptions = computed(() => store.getters['tenant/getSelectedItems'])
  const tenantFilter = ref(null)

  if (router.currentRoute.params.description)
    typeAlarmFilter.value = parseInt(router.currentRoute.description)

  const tableColumns = [
    {label: 'alarm_comment.comment', key: 'comment', sortable: true},
    {
      label: 'alarm_comment.alarm_type', key: 'types_alarms', sortable: false,
      formatter: (value, key, item) => {
        if (value) return value.map(item => {
          return item.description
        }).join(', ')
        return ""
      }, thStyle: 'min-width: 500px',

    },
    {label: 'alarm_comment.tenant', key: 'tenant.name', sortable: true},
    {label: 'alarm_comment.attending', key: 'attending', sortable: true},
    {label: 'alarm_comment.attended', key: 'attended', sortable: true},
    {label: 'alarm_comment.canceled', key: 'canceled', sortable: true},
    {
      label: 'date.created_at',
      key: 'created_at',
      sortable: true,
      formatter: 'formatDateAssigned'
    },
    {
      label: 'date.updated_at',
      key: 'updated_at',
      sortable: true,
      formatter: 'formatDateAssigned'
    },

  ]
  if (ability.can('update', 'AlarmComment') == true || ability.can('delete', 'AlarmComment') == true) {
    tableColumns.splice(0, 0, {label: 'actions.name', key: 'actions'})
  }

  const perPage = ref(10)
  const totalList = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalList.value,
    }
  })


  const fetchList = (ctx, callback) => {
    store.dispatch('alarm_comment/getAll', {
      q: searchQuery.value,
      max: perPage.value,
      'page[n]': currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      type: typeAlarmFilter.value,
    })
      .then(response => {
        callback(response.data)
        totalList.value = response.meta.total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: trans["messages.error.server_error"],
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const formatDateAssigned = value => {
    const formattedDate = moment(value).format('DD/MM/YYYY HH:mm:ss')
    return formattedDate
  }

  const refetchData = () => {
    refListTable.value.refresh()
  }

  watch([ typeAlarmFilter, currentPage, perPage ], () => {
    refetchData()
  })

  watch([ searchQuery ], debounce(() => {
    refetchData()
  }, 500))

  return {
    tableColumns,
    perPage,
    currentPage,
    totalList,
    fetchList,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refListTable,
    formatDateAssigned,
    typeAlarmOptions,
    typeAlarmFilter,
    tenantOptions,
    tenantFilter,
    refetchData,
  }
}
