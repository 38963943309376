<template>
  <b-sidebar
    id="sidebar"
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    no-close-on-backdrop
    right
    @shown="loadData"
    @hidden="resetForm"
    @change="(val) => $emit('update:is-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0 text-capitalize">
          {{
            isAdd ? `${$t("actions.add")} ${$tc("alarm_comment.comment", 0)}` : `${$t("actions.edit")} ${$tc("alarm_comment.comment", 0)}`
          }}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Comment -->
          <validation-provider
            #default="validationContext"
            name="comment"
            rules="required"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('alarm_comment.comment')"
              label-for="comment"
            >
              <b-form-input
                id="comment"
                v-model="itemData.comment"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- status -->
          <validation-provider
            #default="validationContext"
            name="status"
            rules="required"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('alarm_comment.status')"
              label-for="status"
              :state="getValidationState(validationContext)"
            >

              <v-select
                v-model="itemData.status"
                :options="options"
                multiple
                :clearable="false"
                input-id="status"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- type alarm -->
          <validation-provider
            #default="validationContext"
            name="alarm_type"
            rules="required"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('alarm_comment.alarm_type')"
              label-for="type"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="typeAlarmArray"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="typeAlarmOptions"
                multiple
                :clearable="false"
                :reduce="(val) => val.value"
                input-id="id_type_alarm"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- tenant -->
          <validation-provider
            #default="validationContext"
            name="tenant"
            rules="required"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('alarm_comment.tenant')"
              label-for="type"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="itemData.id_tenant"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="tenantOptions"
                :clearable="false"
                :reduce="(val) => val.value"
                input-id="id_tenant"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2 text-capitalize"
              type="submit"
            >
              {{ $t("actions.save") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
              class="text-capitalize"
            >
              {{ $t("actions.cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormCheckbox,
} from "bootstrap-vue";
import {ValidationProvider, ValidationObserver} from "vee-validate";
import vSelect from "vue-select";
import {ref} from "@vue/composition-api";
import {required, alphaNum, email, positive, required_if} from "@validations";
import {useToast} from "vue-toastification/composition";
import ToastificationContent
  from "@core/components/toastification/ToastificationContent.vue";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import {useUtils as useI18nUtils} from "@core/libs/i18n";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isSidebarActive",
    event: "update:is-sidebar-active",
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    itemEdit: {
      type: Object,
      required: false,
    },
    typeAlarmOptions: {
      type: Array,
      required: true,
    },
    tenantOptions: {
      type: Array,
      required: true,
    },
    isAdd: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      positive,
      status: [],
      options: [
        "attending",
        "attended",
        "canceled"
      ]
    };
  },
  setup(props, {emit}) {
    const {t} = useI18nUtils();
    const trans = {
      "messages.edit_save.successfully_created": t(
        "messages.edit_save.successfully_created"
      ),
      "messages.edit_save.updated_successfully": t(
        "messages.edit_save.updated_successfully"
      ),
    };
    const blankData = {
      comment: "",
      id_type_alarm: null,
      id_tenant: null,
    };

    const toast = useToast();

    const itemData = ref(JSON.parse(JSON.stringify(blankData)));
    const resetData = () => {
      itemData.value = JSON.parse(JSON.stringify(blankData));
    };

    const typeAlarmArray = ref([]);
    const onSubmit = () => {
      let dispatch;
      let typeStatus = {attending: 0, attended: 0, canceled: 0}
      itemData.value.status.forEach(value => {
        switch (value) {
          case 'attending':
            typeStatus.attending = 1
            break
          case 'attended':
            typeStatus.attended = 1
            break
          case 'canceled':
            typeStatus.canceled = 1
            break
        }
      })
      const data = {
        comment: itemData.value.comment,
        id_type_alarm: typeAlarmArray.value,
        id_tenant: itemData.value.id_tenant,
        ...typeStatus
      };

      if (props.isAdd) {
        itemData.value.id_type_alarm = typeAlarmArray;
        dispatch = store.dispatch("alarm_comment/add", data);
      } else {
        const req = {
          id: itemData.value.id,
          params: data
        };

        dispatch = store.dispatch("alarm_comment/edit", req);
      }
      dispatch
        .then((response) => {
          if (response.success) {
            toast({
              component: ToastificationContent,
              props: {
                title: props.isAdd
                  ? trans["messages.edit_save.successfully_created"]
                  : trans["messages.edit_save.updated_successfully"],
                icon: "CheckIcon",
                variant: "success",
              },
            });
            emit("refetch-data");
            emit("update:is-sidebar-active", false);
          } else {
            toast({
              component: ToastificationContent,
              props: {
                title: response.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        })
        .catch((response) => {
          toast({
            component: ToastificationContent,
            props: {
              title: response.response.data.message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    };

    const {refFormObserver, getValidationState, resetForm} =
      formValidation(resetData);

    const loadData = () => {
      if (!props.isAdd) {
        itemData.value = props.itemEdit
        typeAlarmArray.value = props.itemEdit.types_alarms.map(
          (item) => item.id
        )

        itemData.value.status = []
        if (props.itemEdit.attending)
          itemData.value.status.push('attending')
        if (props.itemEdit.attended)
          itemData.value.status.push('attended')
        if (props.itemEdit.canceled)
          itemData.value.status.push('canceled')
      }
    };

    return {
      typeAlarmArray,
      itemData,
      onSubmit,
      refFormObserver,
      getValidationState,
      loadData,
      resetForm,
    };
  },
};
</script>